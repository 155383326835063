import React from "react"
import { Nav } from "../components/nav/Nav"
import { HomeHero } from "../components/Home-Hero"

const Errorpage = () => {
  return (
    <>
      <Nav />
      <HomeHero />
    </>
  )
}

export default Errorpage
